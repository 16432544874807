.navbar-dark .navbar-brand{

    max-width: 100%;  
    height: auto;
    font-family: 'big_mountain';
    text-decoration:none;
    color: #ffdd00;
    font-size: 2rem;
    

   
    
}

.nav-item{
    font-family: 'big_mountain';


}

#nav-logo{
    width: 60px;
    height: auto;

}
  
  

.fa-facebook{
    color: rgb(66, 103, 178) ;



}

.fa-instagram{
    color: rgb(253, 29, 29) ;



}

@import url(https://fonts.googleapis.com/css?family=Mr+Dafoe);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:900);
@import url(https://fonts.googleapis.com/css?family=Righteous);
@import url(https://fonts.googleapis.com/css?family=Candal);

@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);

@import url(https://fonts.googleapis.com/css?family=Monoton);