body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@font-face {
  font-family: 'big_mountain';
  src:  url(./fonts/BigMountain-PKZw2.ttf);
}

@font-face {
  font-family: 'rock_biter';

  src:  url(./fonts/Rockbiter-MvwB.ttf);
}


@font-face {
  font-family: 'north-face';
  src:  url(./fonts/NorthFace.ttf);
}
