.news-header{
    width: 100vw;
    height: auto;
    padding-bottom: 5%;
   
  
  }

.background-news{
    background-color: #282c34;
    padding-bottom: 5%;
}

#news-list{
    border: 2px solid rgba(161, 127, 26);
    border-radius: 25px;
    

    


}

p{
    color:whitesmoke ;
}