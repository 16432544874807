
table.tableizer-table {
    border-collapse: collapse;
    height:100vh;
    overflow-y:scroll;
    display:block;
  
    font-size: 0.5em;
    font-family: sans-serif;
    max-width: 100vw;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    
    
} 

.background-franklin{
    background-color: #282c34 !important;



}



.tableizer-table td {
    
    color: white;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgba(161, 127, 26);
}



.tableizer-table th {
    background-color: rgba(161, 127, 26);
    color: white;
    text-align: left;
}

.tableizer-table tbody tr {
    border-bottom: 1px solid #dddddd;
    

}

.tableizer-table tbody tr:nth-of-type(even) {
    background-color: #282c34;
}




.tableizer-table tbody tr:last-of-type {
    border-bottom: 2px solid red;
}

.tableizer-table{
    background-color: black;
}


.gold-0 {
    background: linear-gradient(to bottom, #cfc09f 22%,#634f2c 24%, #cfc09f 26%, #cfc09f 27%,#ffecb3 40%,#3a2c0f 78%); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative;
	text-transform: uppercase;	
	font-size: 9vw;
	margin: 0;
	font-weight: 400;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .gold-1 {
    background: linear-gradient(to bottom, #cfc09f 22%,#634f2c 24%, #cfc09f 26%, #cfc09f 27%,#ffecb3 40%,#3a2c0f 78%); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative;
	text-transform: uppercase;	
	font-size: 3vw;
	margin: 0;
	font-weight: 400;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }