.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;

}

.popup-inner{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #fff;



}

h2{
    background-color: #fff;

}

button{
    margin-top: 2%;
    margin-left: 0%;
    margin-right: 5%;
    padding-right: 1%;
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    
   
}


.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;

}