.notfound{
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    font-size: calc(10px + 2vmin);
    color: white;


}

#bendita-image{
    width: 100vw;
    height: auto;
    padding-bottom: 5%;
}

.victory {
    position: relative;
    padding-bottom: 5%;
  
    font-family: 'Permanent Marker', cursive;
    font-variant:small-caps;
    font-size: 300%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
   
    background-image: -webkit-linear-gradient(#FF0FF8 0%,  #F9F9F7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    -webkit-filter: drop-shadow(2px 2px 20px #f008b7);
  }