.Donate {
    background-color: #282c34;
    padding-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #E1D9D1;


}



#blackbox{
   
    padding-bottom: 2%;
    background-color: black;
    border: 2px solid rgba(161, 127, 26);
    border-radius: 25px;


}

.donate-image{
   
        width: 100vw;
        height: auto;
 
}



