.race_list{
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: #E1D9D1;
  padding-bottom: 10%;
  


    
  
 
  
}

#race-list-test{
    
    border: 2px solid rgba(161, 127, 26);
    border-radius: 25px;


}

.list-group-item{
    background-color: black;
    padding-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    font-size: 150%;






}


.image{
    width: 100vw;
    height: auto;
}
.results_season {
  
    padding-bottom: 5%;
    padding-top: 10%;
    font-family: 'rock_biter';
   
    font-size: 300%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

  }